<template xmlns="http://www.w3.org/1999/html">
  <div class="uk-grid-medium" uk-grid>
    <div class="uk-width-5-5">
      <div class="d-flex">
        <nav id="breadcrumbs" class="mb-3">
          <ul>
            <li>
              <router-link :to="{ name: 'company.dashboard' }">
                <i class="uil-home-alt"></i>
              </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'company.assignments.auto' }">
                Otomatik Atamalar<!-- {{ this.$t('menu.company_dashboard.assignments') }} -->
              </router-link>
            </li>
            <li>Yeni Otomatik Atama<!--{{ this.$t('menu.company_dashboard.assignments') }}--></li>
          </ul>
        </nav>
      </div>

      <div class="section-header mb-lg-2 border-0 uk-flex-middle">
        <div class="section-header-left">
          <h2 class="uk-heading-line text-left"><span>Yeni Otomatik Atama
              <!--{{ this.$t('general.new_assignment') }}--></span></h2>
        </div>
      </div>
      <div class="card">
        <ul class="uk-child-width-expand uk-tab stepper-buttons" id="uk-switcher-tab"
          uk-switcher="connect: #main-switcher-tabs ; animation: uk-animation-slide-left-medium, uk-animation-slide-right-medium">
          <li class="uk-active">
            <a class=" p-4 header-a" href="#"><i class="icon-feather-flag"></i> <b>{{ this.$t('login.header') }}</b></a>
          </li>
          <li>
            <a class="p-4 header-a" href="#"><i class="uil-book-open"></i> <b>Kurallar
                <!--{{ this.$t('messages.content') }}--></b></a>
          </li>
          <!-- <li>
            <a class="p-4 header-a" href="#"><i class="uil-invoice"></i> <b>{{ this.$t('general.overview') }}</b></a>
          </li> -->
        </ul>
        <div>
          <div class="card-body">
            <ul class="uk-switcher uk-margin" id="main-switcher-tabs">
              <!--tab: giris-->
              <li>
                <!--modal-->
                <div id="modal_assignment" class="uk-modal" uk-modal="">
                  <div class="uk-modal-dialog">
                    <button class="uk-modal-close-default" type="button" uk-close></button>
                    <div class="uk-modal-header">
                      <h2 class="uk-modal-title"><i class="uil-location-arrow"></i> Kayıtlı Atamalar</h2>
                    </div>
                    <div class="uk-modal-body h-100">
                      <div class="section-header pb-0">
                        <div class="section-header-left">
                          <input class="mt-3" type="text" :placeholder="$t('filters.search')"
                            v-model="filter.assignmentSearch" />
                        </div>
                        <div class="section-header-right">
                          <div class="btn-group bootstrap-select ml-3" style="height: 35px!important;margin-bottom: 5px;">
                            <select class="selectpicker4 ml-1" @change="sortOrder">
                              <option value="name" data-sort="asc">{{ this.$t("sorting.a_z") }}</option>
                              <option value="name" data-sort="desc">{{ this.$t("sorting.z_a") }}</option>
                              <option value="created_at" data-sort="asc">{{ this.$t("sorting.created_at_asc") }}</option>
                              <option value="created_at" data-sort="desc" selected>{{ this.$t("sorting.created_at_desc")
                              }}</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <!--Content List Here-->
                      <!-- Table -->
                      <div class="table-responsive mt-4">
                        <table class="table align-items-center">
                          <thead>
                            <tr>
                              <th scope="col">Atama Adı</th>
                              <th scope="col">Oluşturulma Tarihi</th>
                            </tr>
                          </thead>
                          <tbody class="list" v-if="formattedItem">
                            <tr v-for="(item, index) in formattedItem">
                              <th scope="row">

                                <span class="name h6 mb-0 text-sm">{{ item.name }}</span>
                                <small class="d-block font-weight-bold"></small>
                              </th>
                              <td>{{ $t(item.created_at) }}</td>
                              <td class="text-right">
                                <div class="actions ml-3">
                                  <template v-if="selectedAssignmentItems.includes(item.id)">
                                    <button class="btn btn-sm btn-success" style="color: #fff"
                                      @click="removeAssignment(item)">
                                      <i class="uil-check"></i>
                                      Eklendi
                                    </button>
                                  </template>
                                  <template v-else>
                                    <button @click="addAssignment(item)" class="btn btn-sm btn-primary"
                                      style="color: #fff">
                                      <i class="uil-plus"></i> Ekle
                                    </button>
                                  </template>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div class="row">
                        <div class="col-12 text-center">
                          <pagination v-if="pagination && pagination.hasOwnProperty('links')" :pagination="pagination"
                            @changePage="setPage($event, 'assignment')"></pagination>
                        </div>
                      </div>
                      <div class="row" style="text-align: end;float:right">
                        <button class="btn btn-sm btn-primary uk-modal-close mr-2" type="button">
                          {{ $t('general.cancel') }}
                        </button>
                        <button class="btn btn-sm btn-primary uk-modal-close" @click="saveAssignment()"
                          :disabled="!selectedAssignmentItems[0]" style="color: #fff;margin-left: 1rem;">
                          {{ $t('general.save') }}
                        </button>
                      </div>
                    </div>

                  </div>
                </div>
                <!--END modal-->
                <div class="row">
                  <div class="col-xl-10 m-auto">
                    <div class="form-group row mb-3">
                      <label class="col-md-3 col-form-label" for="course_title">Otomatik Atama
                        Adı <!--{{ this.$t('general.assignment_name')}}-->
                        <span class="required">*</span></label>
                      <div class="col-md-9">
                        <input type="text" v-model="form.name" class="form-control" id="course_title" required="" />
                      </div>
                    </div>
                    <div class="form-group row mb-3">
                      <div class="col-md-2">
                        <label class="col-form-label">
                          Seçilen Atama <span class="required">*</span>
                        </label>
                      </div>
                      <div class="col-md-10">
                        <span v-if="selectedAssignmentName && form.assignment_id" class="mr-4"><b><i
                              class="uil-location-arrow"></i>{{
                                selectedAssignmentName }}</b></span>
                        <span v-if="!form.assignment_id" class="mr-4"><b>Lütfen Atama
                            Seçiniz</b></span>
                        <button type="button" class="btn btn-default btn-icon-label"
                          uk-toggle="target: #modal_assignment">
                          <span class="btn-inner--icon">
                            <i class="uil-location-arrow"></i>
                          </span>
                          <span class="btn-inner--text">
                            Atama Seç</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="uk-card-footer float-right pb-0 pr-0 mb-3">
                  <button class="uk-button uk-button-primary small" type="button"
                    :disabled="!form['assignment_id'] || !form.name" @click="validateAndSwitch(1)">
                    <i class="icon-feather-arrow-right"></i> {{ this.$t('general.save_and_continue') }}
                  </button>
                </div>
              </li>
              <!--tab: kurallar-->
              <li>
                <!--modal-->
                <div id="modal_rules" class="uk-modal-container" uk-modal="">
                  <div class="uk-modal-dialog">
                    <button class="uk-modal-close-default" type="button" uk-close></button>
                    <div class="uk-modal-header">
                      <h2 class="uk-modal-title"><i class="uil-bolt-alt"></i> Kural Setleri</h2>
                    </div>
                    <div class="uk-modal-body h-100">
                      <div class="form-group row">
                        <div class="col-md-12">
                          <!-- v-model="ruleTypes.id" -->

                          <label class="col-form-label" for="rules_list">Kural Seti<span class="required">*</span></label>
                          <multiselect v-model="selectedRule" @input="changeOption('change')"
                            :tag-placeholder="$t('general.select')" :placeholder="$t('general.select')"
                            :options="selectedEvent ? ruleTypeEvents : ruleTypeStatus" :allow-empty="false"
                            deselectLabel="" selectedLabel="'Seçilmiş'" selectLabel="Seçiniz" label="name" track-by="id"
                            :searchable="true" />
                          <div class="table-responsive mt-4"
                            v-if="form.rules.length !== 0 && selectedRule && !selectedEvent">
                            <table class="table align-items-center">
                              <thead>
                                <tr>
                                  <th scope="col"> {{ selectedRule.name }} </th>
                                </tr>
                              </thead>
                              <div class="row" style="width:100%">
                                <div class=" col-md-1  search-icon ">
                                  <i class="uil-search"></i>
                                </div>
                                <div class="col-md-11 uk-search uk-search-navbar" style="margin-left:-2rem">
                                  <input class="uk-search-input shadow-0 uk-form-small" type="search"
                                    v-model="filter.ruleSearch" :placeholder="$t('general.search')" />
                                </div>
                              </div>
                              <tbody class="list" v-if="ruleItems.length > 0">
                                <tr v-for="(item, index) in ruleItems">
                                  <th scope="row">

                                    <span class="name h6 mb-0 text-sm">{{ item.name }}</span>
                                    <small class="d-block font-weight-bold"></small>
                                  </th>
                                  <td class="text-right">
                                    <div class="actions ml-3">
                                      <template v-if="selectedRuleItems.some((e => e.id === item.id))">
                                        <button class="btn btn-sm btn-success" style="color: #fff"
                                          @click="removeRuleItem(item)">
                                          <i class="uil-check"></i>
                                          Eklendi
                                        </button>
                                      </template>
                                      <template v-else>
                                        <button @click="addRuleItem(item)" class="btn btn-sm btn-primary"
                                          style="color: #fff">
                                          <i class="uil-plus"></i> Ekle
                                        </button>
                                      </template>
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                            <pagination v-if="pagination && pagination.hasOwnProperty('links')"
                              :pagination="paginationRule" @changePage="setPage($event, 'rules')"></pagination>
                          </div>
                          <hr>
                        </div>
                        <div class="col-md-12 mt-3">
                          <div class="uk-alert-access d-flex" uk-alert>
                            <i class="uil-comment-info-alt uk-text-success mr-3" style="font-size:2.4rem;"></i>
                            <p>Bu kural seti kaydedildiğinden itibaren sisteme yeni eklenen kullanıcılara otamatik olarak
                              atamalar yapılır.</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="uk-modal-footer uk-text-right">
                      <button class="uk-button uk-button-primary"
                        :disabled="this.form.rules.length > 0 && selectedRuleItems.length === 0" @click="saveRule()"
                        type="button">KAYDET</button>
                    </div>
                  </div>
                </div>
                <!--END modal-->
                <div class="row">
                  <div class="col-md-12">
                    <div class="mb-2 mx-2 px-2 pt-2 pb-3">
                      <ul class="rules_list">
                        <li>
                          <p class="lead text-center mb-1">Eğer</p>
                        </li>
                        <div v-if="form.rules.length !== 0" class="w-100 d-flex justify-content-between p-2"
                          style="background-color:#f8f8f8">
                          <div><b><i style="color:#666CFF" class="uil-bolt-alt"></i>{{ form.rules[0].name }}</b></div>
                          <div>
                            <button class="uk-button uk-button-link mr-2"
                              @click="openRuleModal('ruleTypeEvents', 0, 'editRule')"><i class="uil-edit"></i></button>
                            <!-- <button class="uk-button uk-button-link uk-text-warning"><i class="uil-trash"></i></button> -->
                          </div>
                        </div>
                        <li v-if="form.rules.length === 0">
                          <button class="uk-button uk-button-primary uk-button-small w-100" type="button"
                            @click="openRuleModal('ruleTypeEvents', 0, 'addRule')">
                            <i class="uil-bolt-alt"></i> Kural Ekle
                          </button>
                        </li>
                        <li>
                          <p class="lead text-center mt-1">ise otomatik ata.</p>
                        </li>
                      </ul>
                      <ul class="rules_list" v-for="(item, index) in form.rules">
                        <!-- <ul class="rules_list"> -->
                        <li v-if="index !== 0">
                          <div class="row p-2" style="background-color:#f8f8f8">
                            <div class="uil-bolt-alt" style="text-align: left;width:18%;"><b><i
                                  style="color:#666CFF"></i>{{
                                    item.name }}:</b></div>
                            <div
                              style="width:72%;white-space: nowrap!important;overflow: hidden!important;text-overflow: ellipsis !important;text-align: left;">
                              <b>
                                <span v-for="(subItem, index) in item.ruleable_names">{{
                                  subItem }}{{ item.ruleable_names.length - 1 !== index ? ', ' : ' ' }} </span> </b>
                            </div>
                            <div style="text-align: end;width:10%;">
                              <button class="uk-button uk-button-link mr-2"
                                @click="openRuleModal('ruleTypeStatus', index, 'editRule')"><i
                                  class="uil-edit"></i></button>
                              <button class="uk-button uk-button-link uk-text-warning" @click="deleteRule(index)"><i
                                  class="uil-trash"></i></button>
                            </div>
                          </div>
                        </li>
                        <li>
                          <select v-model="item.ruleable_operator">
                            <option value="and">Ve</option>
                            <option value="or">Veya</option>
                          </select>
                        </li>
                        <li>
                          <p class="lead text-center mt-1">ise otomatik ata.</p>
                        </li>

                      </ul>
              <li v-if="form.rules.length !== 0">
                <button class="uk-button uk-button-primary uk-button-small w-100" type="button"
                  @click="openRuleModal('ruleTypeStatus', form.rules.length, 'addRule')">
                  <i class="uil-bolt-alt"></i> Kural Ekle
                </button>
              </li>
          </div>
          <div class="uk-card-footer float-right pb-0 pr-0">
            <button class="uk-button uk-button-outline small mr-2" type="button" @click="switcherChange(0)">
              <i class="icon-feather-arrow-left"></i>Önceki
            </button>
            <button class="uk-button uk-button-primary small" type="button" :disabled="form.rules.length < 2"
              @click="saveForm()">
              <i class="icon-feather-arrow-right"></i> {{ this.$t('general.save') }}
            </button>
          </div>
        </div>
      </div>
      </li>
      </ul>
    </div>
  </div>
  </div>
  </div>
  </div>
</template>

<script>
import moment from "moment";
import store from "@/core/services";
import module, {
  BASE_URL,
  MODULE_NAME,
  ERROR,
  ITEMS,
  GET_ITEMS,
  DELETE_ITEM,
  LOADING,
  CREATE_ITEM
} from "@/core/services/store/assignment.module";
import position_module, {
  BASE_URL as POSITION_BASE_URL,
  GET_ITEMS as GET_POSITIONS,
  ITEMS as POSITIONS,
  MODULE_NAME as POSITION_MODULE,
} from "@/core/services/store/position.module";

import title_module, {
  BASE_URL as TITLE_BASE_URL,
  GET_ITEMS as GET_TITLES,
  ITEMS as TITLES,
  MODULE_NAME as TITLE_MODULE,
} from "@/core/services/store/title.module";

import region_module, {
  BASE_URL as REGION_BASE_URL,
  GET_ITEMS as GET_REGIONS,
  ITEMS as REGIONS,
  MODULE_NAME as REGION_MODULE,
} from "@/core/services/store/region.module";

import department_module, {
  BASE_URL as DEPARTMENT_BASE_URL,
  GET_ITEMS as GET_DEPARTMENTS,
  ITEMS as DEPARTMENTS,
  MODULE_NAME as DEPARTMENT_MODULE,
} from "@/core/services/store/department.module";

import branch_module, {
  BASE_URL as BRANCH_BASE_URL,
  GET_ITEMS as GET_BRANCHS,
  ITEMS as BRANCHS,
  MODULE_NAME as BRANCH_MODULE,
} from "@/core/services/store/branch.module";
import Pagination from "@/assets/components/Pagination";
const _POSITION_MODULE = POSITION_MODULE;
const _TITLE_MODULE = TITLE_MODULE;
const _REGION_MODULE = REGION_MODULE;
const _DEPARTMENT_MODULE = DEPARTMENT_MODULE;
const _BRANCH_MODULE = BRANCH_MODULE;
import Multiselect from "vue-multiselect";
export default {
  name: "addAuto",
  components: {
    Pagination,
    Multiselect
  },
  data() {
    return {
      selectedAssignmentName: '',
      selectedRuleIndex: 0,
      selectedOperator: 'and',
      selectedAssignmentItems: [],
      selectedRuleItems: [],
      selectedItemName: '',
      cover_image: null,
      savedAssignments: [],
      errorMessages: [],
      pagination: null,
      activeItem: null,
      successMessage: null,
      company_id: null,
      page: 1,
      per_page: 10,
      filter: {
        assignmentSearch: null,
        ruleSearch: null,
        search: null,
        sort: "desc",
        order_by: "created_at",
      },
      form: {
        name: '',
        assignment_id: null,
        rules: [],
      },
      selectedEvent: false,
      selectedRule: null,
      ruleTypeEvents: [
        { id: 1, name: 'Sisteme Yeni Kullanıcı Eklendiğinde', ruleable_type: 'newUser' }],
      defaultRuleTypeStatus: [
        { id: 2, name: 'Görevi', ruleable_type: 'position' },
        { id: 3, name: 'Ünvanı', ruleable_type: 'title' },
        { id: 4, name: 'GM/Bölgesi', ruleable_type: 'region' },
        { id: 5, name: 'Bölüm/Departmanı', ruleable_type: 'department' },
        { id: 6, name: 'Şube/Mağaza/Distribütör', ruleable_type: 'branch' }
      ],
      ruleTypeStatus: [
        { id: 2, name: 'Görevi', ruleable_type: 'position' },
        { id: 3, name: 'Ünvanı', ruleable_type: 'title' },
        { id: 4, name: 'GM/Bölgesi', ruleable_type: 'region' },
        { id: 5, name: 'Bölüm/Departmanı', ruleable_type: 'department' },
        { id: 6, name: 'Şube/Mağaza/Distribütör', ruleable_type: 'branch' }
      ],
      ruleItems: [],
      paginationRule: null
    };
  },
  beforeCreate() {
    function registerStoreModule(moduleName, storeModule) {
      if (!(store && store.state && store.state[moduleName])) {
        store.registerModule(moduleName, storeModule);
      }
    }
    registerStoreModule(MODULE_NAME, module);
    registerStoreModule(_POSITION_MODULE, position_module);
    registerStoreModule(_TITLE_MODULE, title_module);
    registerStoreModule(_REGION_MODULE, region_module);
    registerStoreModule(_DEPARTMENT_MODULE, department_module);
    registerStoreModule(_BRANCH_MODULE, branch_module);
  },
  computed: {
    userInfo: {
      get: function () {
        return JSON.parse(this.secureLocalStorage("user"));
      },
      set: function (newValue) { },
    },
    branchs: {
      get() {
        return store.getters[BRANCH_MODULE + "/" + BRANCHS];
      },
      set(value) { },
    },
    departments: {
      get() {
        return store.getters[DEPARTMENT_MODULE + "/" + DEPARTMENTS];
      },
      set(value) { },
    },
    regions: {
      get() {
        return store.getters[REGION_MODULE + "/" + REGIONS];
      },
      set(value) { },
    },
    titles: {
      get() {
        return store.getters[TITLE_MODULE + "/" + TITLES];
      },
      set(value) { },
    },
    positions: {
      get() {
        return store.getters[POSITION_MODULE + "/" + POSITIONS];
      },
      set(value) { },
    },
    items: {
      get() {
        return store.getters[MODULE_NAME + "/" + ITEMS];
      },
      set(value) { },
    },
    formattedItem() {
      let results = [];
      if (this.items === null || !this.items || this.items.length === 0) return [];
      this.pagination = this.items;
      this.items.data.forEach((currentItem) => {
        results.push({
          id: currentItem.id,
          name: currentItem.name,
          hasAssigned: currentItem.users_count,
          cover_image_link: currentItem.cover_image_link,
          description: currentItem.description ? currentItem.description : "-",
          order_number: currentItem.repeatable_main_item
            ? "Ana İçerik Ataması"
            : currentItem.order_number + ". Tekrarlı Atama",
          repeatable:
            currentItem.repeatable_main_item || currentItem.repeatable
              ? true
              : false,
          has_gift: currentItem.has_gift
            ? this.$t("general.yes")
            : this.$t("general.no"),
          created_at: currentItem.created_at
            ? moment(currentItem.created_at).format("DD/MM/YYYY HH:mm:ss")
            : "-",
          start_end:
            currentItem.start_date && currentItem.end_date
              ? moment(currentItem.start_date).format("DD-MM-YYYY") +
              " / " +
              moment(currentItem.end_date).format("DD-MM-YYYY")
              : "-",
        });
      });

      return results;
    },
    error: {
      get() {
        return store.getters[MODULE_NAME + "/" + ERROR];
      },
      set(value) {
      },
    },
  },
  methods: {
    deleteRule(index) {
      this.form.rules.splice(index, 1);
    },
    openRuleModal(type, index = 0, event = 'addRule') {
      this.filter.ruleSearch = null;
      this.selectedRuleIndex = index;
      this.page = 1;
      this.selectedRule = this.form.rules[index];
      if (type === 'ruleTypeStatus') {
        this.ruleTypeStatus = [...this.defaultRuleTypeStatus];
        this.selectedEvent = false;
        if (event === 'editRule') {
          this.form.rules[index].ruleable_id.forEach((ruleId, ruleIndex) => {
            this.selectedRuleItems.push({ name: this.form.rules[index].ruleable_names[ruleIndex], id: ruleId });
          });
          let spliceIndex = null
          this.form.rules.forEach((defaultRule, defaultRuleIndex) => {
            this.ruleTypeStatus.forEach((selectedRule, selectedRuleIndex) => {
              if (defaultRule.ruleable_type === selectedRule.ruleable_type && this.form.rules[index].ruleable_type !== selectedRule.ruleable_type) {
                spliceIndex = selectedRuleIndex;
                this.ruleTypeStatus.splice(spliceIndex, 1);
              }
            })
          })
          this.changeOption();
        } else if (event === 'addRule') {
          let spliceIndex = null
          this.form.rules.forEach((defaultRule, defaultRuleIndex) => {
            this.ruleTypeStatus.forEach((selectedRule, selectedRuleIndex) => {
              if (defaultRule.ruleable_type === selectedRule.ruleable_type) {
                spliceIndex = selectedRuleIndex;
                this.ruleTypeStatus.splice(spliceIndex, 1);
              }
            })
          })
        }
      } else if (type === 'ruleTypeEvents') {
        this.selectedEvent = true
      }

      UIkit.modal("#modal_rules").show();
    },
    removeRuleItem(item) {
      this.selectedRuleItems.every((data, index) => {
        if (data.id === item.id) {
          this.selectedRuleItems.splice(index, 1)
          return false
        }
        else {
          return true;
        }
      })
    },
    addRuleItem(item) {
      this.selectedRuleItems.push({ name: item.name, id: item.id });
    },
    changeOption(event = null) {
      if (event === 'change')
        this.selectedRuleItems = []
      if (this.selectedRule['ruleable_type'] === 'position') {
        this.paginationRule = this.positions;
        this.ruleItems = this.positions.data;
      }
      else if (this.selectedRule['ruleable_type'] === 'title') {
        this.paginationRule = this.titles;
        this.ruleItems = this.titles.data;
      }
      else if (this.selectedRule['ruleable_type'] === 'region') {
        this.paginationRule = this.regions;
        this.ruleItems = this.regions.data;
      }
      else if (this.selectedRule['ruleable_type'] === 'department') {
        this.paginationRule = this.departments;
        this.ruleItems = this.departments.data;
      }
      else if (this.selectedRule['ruleable_type'] === 'branch') {
        this.paginationRule = this.branchs;
        this.ruleItems = this.branchs.data;
      }
    },
    getBranchs() {
      this.$store.dispatch(BRANCH_MODULE + "/" + GET_BRANCHS, {
        url: BRANCH_BASE_URL,
        filters: {
          name: this.filter.ruleSearch,
          company_id: this.company_id,
          per_page: this.per_page,
          page: this.page
        },
      }).then((response) => {
        this.ruleItems = response.data;
      });;
    },
    getDepartments() {
      this.$store.dispatch(DEPARTMENT_MODULE + "/" + GET_DEPARTMENTS, {
        url: DEPARTMENT_BASE_URL,
        filters: {
          name: this.filter.ruleSearch,
          company_id: this.company_id,
          per_page: this.per_page,
          page: this.page
        },
      }).then((response) => {
        this.ruleItems = response.data;
      });;
    },
    getRegions() {
      this.$store.dispatch(REGION_MODULE + "/" + GET_REGIONS, {
        url: REGION_BASE_URL,
        filters: {
          name: this.filter.ruleSearch,
          company_id: this.company_id,
          per_page: this.per_page,
          page: this.page
        },
      }).then((response) => {
        this.ruleItems = response.data;
      });;
    },
    getTitles() {
      this.$store.dispatch(TITLE_MODULE + "/" + GET_TITLES, {
        url: TITLE_BASE_URL,
        filters: {
          name: this.filter.ruleSearch,
          company_id: this.company_id,
          per_page: this.per_page,
          page: this.page
        },
      }).then((response) => {
        this.ruleItems = response.data;
      });;
    },
    getPositions() {
      this.$store.dispatch(POSITION_MODULE + "/" + GET_POSITIONS, {
        url: POSITION_BASE_URL,
        filters: {
          name: this.filter.ruleSearch,
          company_id: this.company_id,
          per_page: this.per_page,
          page: this.page
        },
      }).then((response) => {
        this.paginationRule = this.positions;
        this.ruleItems = response.data;
      });
    },
    saveForm() {
      this.form.rules.splice(0, 1);
      store.dispatch(MODULE_NAME + "/" + CREATE_ITEM, {
        url: 'api/automatic-assignments',
        contents: this.form,
      }).then(() => {
        if (!this.error) {
          this.successMessage = this.$t("general.successfully_created");
          this.$router.push({ name: "company.assignments.auto" });
        } else this.errorMessages.push(this.error);
      });
    },
    validateAndSwitch(index) {
      this.switcherChange(index)
    },
    switcherChange(index) {
      UIkit.switcher($("#uk-switcher-tab")[0]).show(index);
    },
    saveRule() {
      let ids = [];
      let names = [];
      this.selectedRuleItems.forEach((data) => {
        ids.push(data.id)
        names.push(data.name)
      })
      if (this.form.rules.length === this.selectedRuleIndex) {
        this.form.rules.push({
          name: this.selectedRule['name'],
          ruleable_id: ids,
          ruleable_type: this.selectedRule['ruleable_type'],
          ruleable_operator: this.selectedOperator ?? 'and',
          ruleable_names: names
        });
      } else {
        this.form.rules[this.selectedRuleIndex].name = this.selectedRule['name'];
        this.form.rules[this.selectedRuleIndex].ruleable_id = ids,
          this.form.rules[this.selectedRuleIndex].ruleable_type = this.selectedRule['ruleable_type'];
        this.form.rules[this.selectedRuleIndex].ruleable_operator = this.selectedOperator ?? 'and'
        this.form.rules[this.selectedRuleIndex].ruleable_names = names
      }
      this.selectedRule = [];
      this.selectedRuleItems = [];
      UIkit.modal("#modal_rules").hide();
    },
    sortOrder(event) {
      let target = event.target;
      this.page = 1;
      this.filter.sort = target.selectedOptions[0].getAttribute("data-sort");
      this.filter.order_by = target.value;
      this.getAssignmentResult();
    },
    setPage(page = null, event = null) {
      page ? this.page = page : this.page = 1;
      if (event === 'rules' || !event) {
        if (this.selectedRule['ruleable_type'] === 'position') {
          this.getPositions();
        }
        else if (this.selectedRule['ruleable_type'] === 'title') {
          this.getTitles();

        }
        else if (this.selectedRule['ruleable_type'] === 'region') {
          this.getRegions();
        }
        else if (this.selectedRule['ruleable_type'] === 'department') {
          this.getDepartments();
        }
        else if (this.selectedRule['ruleable_type'] === 'branch') {
          this.getBranchs();
        }
      } else if (event === 'assignment') {
        this.getAssignmentResult();
      }
    },
    addAssignment(item) {
      this.selectedAssignmentItems = []
      this.selectedItemName = item.name
      this.selectedAssignmentItems.push(item.id)
    },
    removeAssignment(item) {
      this.selectedAssignmentItems.every((data, index) => {
        if (data === item.id) {
          this.selectedAssignmentItems.splice(index, 1)
          return false
        }
        else {
          return true;
        }
      })
    },
    saveAssignment() {
      this.form.assignment_id = this.selectedAssignmentItems[0];
      this.selectedAssignmentName = this.selectedItemName;
    },
    getAssignmentResult() {
      this.$store.dispatch(MODULE_NAME + "/" + GET_ITEMS, {
        url: BASE_URL,
        filters: {
          search: this.filter.assignmentSearch,
          page: this.page,
          per_page: this.per_page,
          sort: this.filter.sort,
          order_by: this.filter.order_by,
          with_expired: false
        },
      });
    },
    processFile(event) {
      this.cover_image = event.target.files[0];
      let blob = URL.createObjectURL(this.cover_image);
      let imageHolder = $(".logoarea");
      imageHolder.attr("src", blob);
    },
    inputClick() {
      $("#cover_photo_input").click();
    },
    resetMessages() {
      this.successMessage = null;
      this.errorMessages = [];
    },
    scrollToTop() {
      window.scroll({
        top: 0,
        behavior: "smooth",
      });
    },
  },
  mounted() {
    this.company_id = this.userInfo.company_id;
    this.getAssignmentResult();
    this.getPositions();
    this.getTitles();
    this.getRegions();
    this.getDepartments();
    this.getBranchs();
    $(".selectpicker4").selectpicker()
  },
  beforeDestroy() {
    const modal = UIkit.modal("#modal_assignment");
    if (modal) modal.$destroy(true);
    const modalRules = UIkit.modal("#modal_rules");
    if (modalRules) modalRules.$destroy(true);
  },
  watch: {
    "filter.ruleSearch": function (val) {
      this.debouncedFilterRuleSearch();
    },
    "filter.assignmentSearch": function (val) {
      this.debouncedFilterAssignmentSearch();
    },
    successMessage: function (val) {
      this.debouncedResetValues();
    },
    errorMessages: function (val) {
      this.debouncedResetValues();
    },
  },
  created: function () {
    this.debouncedFilterRuleSearch = _.debounce(this.setPage, 500);
    this.debouncedFilterAssignmentSearch = _.debounce(this.getAssignmentResult, 500);
    this.debouncedResetValues = _.debounce(this.resetMessages, 2000);
  },
};
</script>
<style scoped>
input[type="button"],
input[type="submit"],
span.btn,
.btn {
  display: inline-block;
}

.dragBox input[type="file"] {
  position: absolute;
  height: 100%;
  width: 100%;
  opacity: 0;
  top: 0;
  left: 0;
}

.dragBox {
  width: auto;
  padding: 0 10px;
  height: 100px;
  margin: 0 auto;
  position: relative;
  text-align: center;
  font-weight: bold;
  line-height: 95px;
  color: #999;
  border: 2px dashed #ccc;
  display: inline-block;
  -webkit-transition: -webkit-transform 0.3s;
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
}

.uploadOuter strong {
  padding: 0 10px;
}

.uploadOuter {
  text-align: center;
  padding: 20px;
}
</style>
<style>
.multiselect__tag {
  color: white;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  background-color: #3e416d;
}

.multiselect__tag-icon {
  background-color: transparent;
  line-height: 2.2;
}

.multiselect__tag-icon:after {
  color: #fff;
}

.multiselect__tag-icon:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.multiselect__input {
  border: unset !important;
}

.multiselect__tags .multiselect__input {
  border: unset !important;
}

.header-a {
  cursor: default;
  pointer-events: none;
}

.bootstrap-select.open .dropdown-menu {
  opacity: 1 !important;
  pointer-events: all !important;
  visibility: visible !important;
}

.inner {
  opacity: 1 !important;
  visibility: visible !important;
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
